import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ab3d422 = () => interopDefault(import('../pages/ask.vue' /* webpackChunkName: "pages/ask" */))
const _879db85c = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _874ffaa2 = () => interopDefault(import('../pages/crosscheck.vue' /* webpackChunkName: "pages/crosscheck" */))
const _bb047776 = () => interopDefault(import('../pages/data-protection-and-privacy-policy.vue' /* webpackChunkName: "pages/data-protection-and-privacy-policy" */))
const _d5b78df8 = () => interopDefault(import('../pages/idioma-nmt.vue' /* webpackChunkName: "pages/idioma-nmt" */))
const _16f17920 = () => interopDefault(import('../pages/iglossary.vue' /* webpackChunkName: "pages/iglossary" */))
const _4fd47a6d = () => interopDefault(import('../pages/isync.vue' /* webpackChunkName: "pages/isync" */))
const _9289f6e4 = () => interopDefault(import('../pages/nextdoc.vue' /* webpackChunkName: "pages/nextdoc" */))
const _7f682e0e = () => interopDefault(import('../pages/nmt-plus-pe.vue' /* webpackChunkName: "pages/nmt-plus-pe" */))
const _54e9aec2 = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _6df0b10e = () => interopDefault(import('../pages/resources.vue' /* webpackChunkName: "pages/resources" */))
const _499e6b05 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _168ac0e7 = () => interopDefault(import('../pages/stream.vue' /* webpackChunkName: "pages/stream" */))
const _ce367c98 = () => interopDefault(import('../pages/stream-api.vue' /* webpackChunkName: "pages/stream-api" */))
const _5324729b = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _394fe48a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _82a9b09a = () => interopDefault(import('../pages/_ui/colors.vue' /* webpackChunkName: "pages/_ui/colors" */))
const _5a7450b2 = () => interopDefault(import('../pages/_ui/icons.vue' /* webpackChunkName: "pages/_ui/icons" */))
const _5c451a72 = () => interopDefault(import('../pages/_ui/typography.vue' /* webpackChunkName: "pages/_ui/typography" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ask",
    component: _3ab3d422,
    name: "ask___en"
  }, {
    path: "/contact-us",
    component: _879db85c,
    name: "contact-us___en"
  }, {
    path: "/crosscheck",
    component: _874ffaa2,
    name: "crosscheck___en"
  }, {
    path: "/data-protection-and-privacy-policy",
    component: _bb047776,
    name: "data-protection-and-privacy-policy___en"
  }, {
    path: "/idioma-nmt",
    component: _d5b78df8,
    name: "idioma-nmt___en"
  }, {
    path: "/iglossary",
    component: _16f17920,
    name: "iglossary___en"
  }, {
    path: "/isync",
    component: _4fd47a6d,
    name: "isync___en"
  }, {
    path: "/nextdoc",
    component: _9289f6e4,
    name: "nextdoc___en"
  }, {
    path: "/nmt-plus-pe",
    component: _7f682e0e,
    name: "nmt-plus-pe___en"
  }, {
    path: "/password",
    component: _54e9aec2,
    name: "password___en"
  }, {
    path: "/resources",
    component: _6df0b10e,
    name: "resources___en"
  }, {
    path: "/services",
    component: _499e6b05,
    name: "services___en"
  }, {
    path: "/stream",
    component: _168ac0e7,
    name: "stream___en"
  }, {
    path: "/stream-api",
    component: _ce367c98,
    name: "stream-api___en"
  }, {
    path: "/terms-and-conditions",
    component: _5324729b,
    name: "terms-and-conditions___en"
  }, {
    path: "/ja/",
    component: _394fe48a,
    name: "index___ja"
  }, {
    path: "/ja/ask",
    component: _3ab3d422,
    name: "ask___ja"
  }, {
    path: "/ja/contact-us",
    component: _879db85c,
    name: "contact-us___ja"
  }, {
    path: "/ja/crosscheck",
    component: _874ffaa2,
    name: "crosscheck___ja"
  }, {
    path: "/ja/data-protection-and-privacy-policy",
    component: _bb047776,
    name: "data-protection-and-privacy-policy___ja"
  }, {
    path: "/ja/idioma-nmt",
    component: _d5b78df8,
    name: "idioma-nmt___ja"
  }, {
    path: "/ja/iglossary",
    component: _16f17920,
    name: "iglossary___ja"
  }, {
    path: "/ja/isync",
    component: _4fd47a6d,
    name: "isync___ja"
  }, {
    path: "/ja/nextdoc",
    component: _9289f6e4,
    name: "nextdoc___ja"
  }, {
    path: "/ja/nmt-plus-pe",
    component: _7f682e0e,
    name: "nmt-plus-pe___ja"
  }, {
    path: "/ja/password",
    component: _54e9aec2,
    name: "password___ja"
  }, {
    path: "/ja/resources",
    component: _6df0b10e,
    name: "resources___ja"
  }, {
    path: "/ja/services",
    component: _499e6b05,
    name: "services___ja"
  }, {
    path: "/ja/stream",
    component: _168ac0e7,
    name: "stream___ja"
  }, {
    path: "/ja/stream-api",
    component: _ce367c98,
    name: "stream-api___ja"
  }, {
    path: "/ja/terms-and-conditions",
    component: _5324729b,
    name: "terms-and-conditions___ja"
  }, {
    path: "/sv/",
    component: _394fe48a,
    name: "index___sv"
  }, {
    path: "/sv/ask",
    component: _3ab3d422,
    name: "ask___sv"
  }, {
    path: "/sv/contact-us",
    component: _879db85c,
    name: "contact-us___sv"
  }, {
    path: "/sv/crosscheck",
    component: _874ffaa2,
    name: "crosscheck___sv"
  }, {
    path: "/sv/data-protection-and-privacy-policy",
    component: _bb047776,
    name: "data-protection-and-privacy-policy___sv"
  }, {
    path: "/sv/idioma-nmt",
    component: _d5b78df8,
    name: "idioma-nmt___sv"
  }, {
    path: "/sv/iglossary",
    component: _16f17920,
    name: "iglossary___sv"
  }, {
    path: "/sv/isync",
    component: _4fd47a6d,
    name: "isync___sv"
  }, {
    path: "/sv/nextdoc",
    component: _9289f6e4,
    name: "nextdoc___sv"
  }, {
    path: "/sv/nmt-plus-pe",
    component: _7f682e0e,
    name: "nmt-plus-pe___sv"
  }, {
    path: "/sv/password",
    component: _54e9aec2,
    name: "password___sv"
  }, {
    path: "/sv/resources",
    component: _6df0b10e,
    name: "resources___sv"
  }, {
    path: "/sv/services",
    component: _499e6b05,
    name: "services___sv"
  }, {
    path: "/sv/stream",
    component: _168ac0e7,
    name: "stream___sv"
  }, {
    path: "/sv/stream-api",
    component: _ce367c98,
    name: "stream-api___sv"
  }, {
    path: "/sv/terms-and-conditions",
    component: _5324729b,
    name: "terms-and-conditions___sv"
  }, {
    path: "/ja/:ui/colors",
    component: _82a9b09a,
    name: "ui-colors___ja"
  }, {
    path: "/ja/:ui/icons",
    component: _5a7450b2,
    name: "ui-icons___ja"
  }, {
    path: "/ja/:ui/typography",
    component: _5c451a72,
    name: "ui-typography___ja"
  }, {
    path: "/sv/:ui/colors",
    component: _82a9b09a,
    name: "ui-colors___sv"
  }, {
    path: "/sv/:ui/icons",
    component: _5a7450b2,
    name: "ui-icons___sv"
  }, {
    path: "/sv/:ui/typography",
    component: _5c451a72,
    name: "ui-typography___sv"
  }, {
    path: "/",
    component: _394fe48a,
    name: "index___en"
  }, {
    path: "/:ui/colors",
    component: _82a9b09a,
    name: "ui-colors___en"
  }, {
    path: "/:ui/icons",
    component: _5a7450b2,
    name: "ui-icons___en"
  }, {
    path: "/:ui/typography",
    component: _5c451a72,
    name: "ui-typography___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
